import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql, Link } from "gatsby";
import PreviewCompatibleImage from "../PreviewCompatibleImage";

import './BlogPostsSection.scss';

const BlogPostsSection = ({ data, title }) => {
    const { edges: posts } = data.allMarkdownRemark;
    return (
        <div id="stay-in" className="container">
            <section>
                <div>
                    <div className="column is-10 is-offset-1">
                        <div className="h1-title">
                            <h1 className="h1-title">{title}</h1>
                        </div>
                        <div className="columns is-centered">
                            {posts && posts.map(({ node: post }) => (
                                <div className="is-parent column" key={post.id}>
                                    <article className="blog-list-item is-child">
                                        <header className="tile is-vertical columns is-centered">
                                            <div className="featured-thumbnail column" style={{
                                                margin: '20px 0 -40px 0',
                                                minWidth: 220,
                                                minHeight: 286
                                            }}>
                                                {post.frontmatter.featuredimage ? (
                                                    <PreviewCompatibleImage
                                                    imageInfo={{
                                                        image: post.frontmatter.featuredimage,
                                                            alt: `featured image thumbnail for post ${post.frontmatter.title}`
                                                    }}/>
                                                ) : null}
                                            </div>
                                            <span className="tile has-text-centered subtitle has-text-weight-bold is-size-5 is-block column post-date">
                                                {post.frontmatter.date}
                                            </span>
                                            <p className="tile post-meta column">
                                                <Link
                                                className="title has-text-primary has-text-centered is-size-4 responsive-title" style={{
                                                    margin: '-30px 0 0 0',
                                                }}
                                                to={post.fields.slug}>
                                                    {post.frontmatter.title}
                                                </Link>
                                            </p>
                                        </header>
                                        <p className="has-text-centered post-excerpt">
                                            {post.excerpt}
                                        </p>
                                    </article>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

BlogPostsSection.propTypes = {
    data: PropTypes.shape({
        allMarkdownRemark: PropTypes.shape({
            edges: PropTypes.array
        })
    })
};

export default ({title}) => (
    <StaticQuery
        query={graphql`
        query BlogPostsSectionQuery {
            allMarkdownRemark(
                sort: { order: DESC, fields: [frontmatter___date] }
                filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
                limit: 3
            ) {
                edges {
                    node {
                        excerpt(pruneLength: 200)
                        id
                        fields {
                            slug
                        }
                        frontmatter {
                            title
                            templateKey
                            date(formatString: "DD/MM/YY")
                            featuredpost
                            featuredimage {
                                childImageSharp {
                                    fluid(maxWidth: 400, quality: 100) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        `}
        render={(data) => <BlogPostsSection data={data} title={title} />}
    />
);
