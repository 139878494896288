import React from 'react';
import PropTypes from 'prop-types';
import { kebabCase } from 'lodash';
import { Helmet } from 'react-helmet';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import Content, { HTMLContent } from '../components/Content';
import BlogBanner from '../components/blog/BlogBanner';
import BlogPostsSection from '../components/blog/BlogPostsSection';
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

import './blog-post.scss';

export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  date,
  featuredimage,
  helmet,
}) => {
  const PostContent = contentComponent || Content

  return (
    <section className="post-section">
      {helmet || ''}
      <article className="post-article">
        <header className="post-header">
          <h3 className="date">{date}</h3>
          <h1 className="title">
            {title}
          </h1>
        </header>
        <section className="post-image">
          <PreviewCompatibleImage
            imageInfo={{
              image: featuredimage,
              alt: `featured image thumbnail for post ${title}`,
            }}
          />
        </section>
        <main>
          <p className="post-description">{description}</p>
          <PostContent className="post-content" content={content} />
        </main>
        <footer>
          {tags && tags.length ? (
            <div style={{ marginTop: `4rem` }}>
              <ul className="taglist">
                {tags.map((tag) => (
                  <li key={tag + `tag`}>
                    <Link to={`/tags/${kebabCase(tag)}/`}>#{tag}</Link>
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
        </footer>
      </article>
    </section>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <Link className="back-link" to="/blog">
        <span>&lt;</span>
        <span>Blog</span>
        <span></span>
      </Link>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        date={post.frontmatter.date}
        featuredimage={post.frontmatter.featuredimage}
        title={post.frontmatter.title}
      />
      <BlogBanner />
      <BlogPostsSection title="veja também" />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "DD/MM/YY")
        title
        description
        tags
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 400, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
